<template>
    <myOrdersWrapper ref="MyOrders" :tableType="2"></myOrdersWrapper>
</template>

<script>
    import MyOrdersWrapper from './layout/myOrdersWrapper.vue'
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';
    export default {
        name: "MyOrdersGrabbedJob",
        components: {
            MyOrdersWrapper
        },
        data(){
            return{
                
            }
        },
        mixins: [tableScrollTo],
        activated() {
            this.tableScrollTo();
        },
    }
</script>

<style scoped>

</style>
